import React, { useState, useEffect, createContext } from 'react';
import { SiteRoutes } from './Routes';

import { ConfigProvider, theme } from 'antd';

// Polyfill for older browsers
import "core-js/stable";

import "./App.css";

export const ThemeContext = createContext();

export const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setDarkMode(localStorage.getItem('blockpal-theme') === 'dark');
  }, []);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <ConfigProvider
        theme={{
          "algorithm": darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          "token": {
            "fontFamily": "'Quicksand', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
            "colorPrimary": "#019cde",
            "fontSize": 15,
            "borderRadius": 8,
            "wireframe": true,
            "sizeStep": 4,
            "sizeUnit": 4,
            "colorSuccess": "#26d22d",
            "colorError": "#d23a25",
            "colorInfo": "#019cde",
            "colorBgLayout": darkMode ? "#111" : "#fff",
          }
        }}
      >
        <SiteRoutes />
      </ConfigProvider>
    </ThemeContext.Provider>
  );
}