import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const GamesPage = lazy(() => import('./Pages/GamesPage'));
const GuildsPage = lazy(() => import('./Pages/GuildsPage'));
const PlayersPage = lazy(() => import('./Pages/PlayersPage'));

export function SiteRoutes() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Routes>

            <Route path="/">
              <Route index element={<GamesPage />} />
              <Route path=":gameId/guilds" element={<GuildsPage />} />
              <Route path=":gameId/:guildId/players" element={<PlayersPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
